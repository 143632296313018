import React, { useState } from "react";
import Image from "@/assets/profileAvatar.png"; // Adjust the import path based on your file structure
import { useToast } from "@/components/ui/use-toast"; // Import the toast hook

interface LogoInputProps {
  id: string;
  register: any; // Replace with the appropriate type for register
  error?: { message?: string }; // Replace with the appropriate error type
  label?: string; // Add label prop here
}

const LogoInput: React.FC<LogoInputProps> = ({ id, register, error, label }) => {
  const [preview, setPreview] = useState<string | null>(null);
  const { toast } = useToast(); // Initialize the toast

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // Get the selected files
    if (files && files.length > 0) {
      const file = files[0]; // Select the first file

      // Check file size
      if (file.size > 2 * 1024 * 1024) { // Check if file is larger than 2MB
        toast({ // Display toast notification
          title: "Error",
          description: "File size exceeds 2MB.",
          variant: "destructive",
        });
        setPreview(null); // Clear preview
        return; // Exit the function
      }

      const reader = new FileReader();

      // Set the preview image
      reader.onloadend = () => {
        setPreview(reader.result as string); // Set the preview
      };
      reader.readAsDataURL(file); // Convert the file to base64

      // Trigger registration update
      register.onChange(event); // Update the registered value
    } else {
      setPreview(null); // Clear preview if no file
    }
  };

  return (
    <div className="flex flex-col items-start mb-4">
      {/* Render the label if provided */}
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700 mb-2 dark:text-white">
          {label}
        </label>
      )}

      <div className="flex flex-col sm:flex-row sm:items-center">
        <label htmlFor={id} className="relative cursor-pointer">
          <input
            id={id}
            type="file"
            {...register}
            onChange={handleChange}
            accept="image/*"
            className="hidden"
          />
          <div className="mb-4 sm:mb-0 sm:mr-4">
            {preview ? (
              <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-300">
                <img
                  src={preview}
                  alt="Logo Preview"
                  className="w-full h-full object-cover"
                />
              </div>
            ) : (
              <div className="w-24 h-24 rounded-full overflow-hidden border-2 border-gray-300">
                <img
                  src={Image}
                  alt="Default Logo"
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>
        </label>
        <div className="mt-4 sm:mt-0 sm:ml-4 flex flex-col text-gray-500 dark:text-gray-300">
          {error && <span className="text-red-500 text-sm mb-1">{error.message}</span>}
          <p className="text-xs  whitespace-nowrap">
            Preferred image dimension: <span className="font-bold">94px x 94px</span>
          </p>
          <p className="text-xs whitespace-nowrap">
            Maximum image size: <span className="font-bold">2MB</span>
          </p>
          <p className="text-xs whitespace-nowrap">
            Preferred file type: <span className="font-bold">.png, .jpg, .jpeg</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LogoInput;
