import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { createRegisterAuthSlice, RegisterAuthState } from "./registerSlice";
import { createAdSlice, AdState } from "./adSlice";
import { createLoginAuthSlice, LoginAuthState } from "./loginSlice";
import { createORGAuthSlice, ORGLoginAuthState } from "./orgLoginSlice";
import { createAdApiSlice, AdApiState } from "./createAdSlice";
import { createVatCollectionSlice, VatCollectionState } from "./vatSlice";

type StoreState = RegisterAuthState &
  AdState &
  LoginAuthState &
  AdApiState &
  VatCollectionState &
  ORGLoginAuthState;

const useStore = create<StoreState>()(
  devtools((set, get, api) => ({
    ...createRegisterAuthSlice(set, get, api),
    ...createAdSlice(set, get, api),
    ...createLoginAuthSlice(set, get, api),
    ...createORGAuthSlice(set, get, api),
    ...createAdApiSlice(set, get, api),
    ...createVatCollectionSlice(set, get, api),
  }))
);

export default useStore;
