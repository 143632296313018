// src/utils/mixpanel.ts

import mixpanel from 'mixpanel-browser';

class MixpanelService {
  private static instance: MixpanelService;

  private constructor() {
    const MIXPANEL_TOKEN = import.meta.env.REACT_APP_MIXPANEL_TOKEN || '894d9b5879305bbbd3cd8cfb8296613c';
    if (!MIXPANEL_TOKEN) {
      console.error("Mixpanel token is missing!");
      return;
    }

    mixpanel.init(MIXPANEL_TOKEN, {
      debug: import.meta.env.NODE_ENV === 'development', // Enable debug mode in development
    });
  }

  public static getInstance(): MixpanelService {
    if (!MixpanelService.instance) {
      MixpanelService.instance = new MixpanelService();
    }
    return MixpanelService.instance;
  }

  public track(event: string, properties?: Record<string, any>): void {
    mixpanel.track(event, properties);
  }

  public identify(userId: string): void {
    mixpanel.identify(userId);
  }

  public alias(alias: string): void {
    mixpanel.alias(alias);
  }

  public setProfileProperties(properties: Record<string, any>): void {
    mixpanel.people.set(properties);
  }

  public setProfileProperty(property: string, value: any): void {
    mixpanel.people.set(property, value);
  }

  public incrementProfileProperty(property: string, by: number): void {
    mixpanel.people.increment(property, by);
  }

  // Add other Mixpanel methods as needed
}

export const Mixpanel = MixpanelService.getInstance();
