// src/store/adApiSlice.ts
import { StateCreator } from "zustand";
import type { FormData } from "@/components/types/internal/types/FormDataTypes";
import { postFormData } from "@/utils/api";
import { handleApiError } from "@/utils/errorHandler";

 export interface AdApiState {
  submitAd: (data: FormData) => Promise<void>;
  submitStatus: 'idle' | 'loading' | 'success' | 'error';
  submitError: string | null;
}

export const createAdApiSlice: StateCreator<AdApiState> = (set) => ({
  submitStatus: 'idle',
  submitError: null,

  submitAd: async (data: FormData) => {
    set({ submitStatus: 'loading', submitError: null });

    try {
      const formDataToSend = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        if (value !== null) {
          formDataToSend.append(key, value as Blob | string);
        }
      });

      await postFormData("/ads/createAd", formDataToSend);
      set({ submitStatus: 'success' });
    } catch (error) {
      const errorMessage = handleApiError(error);
      set({ submitStatus: 'error', submitError: errorMessage });
      console.error("Failed to submit form:", errorMessage);
    }
  },
});
