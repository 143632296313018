import React from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog"; // Ensure the import path is correct

interface TermsAndConditionsDialogProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

const TermsAndConditionsDialog: React.FC<TermsAndConditionsDialogProps> = ({
    open,
    onOpenChange,
}) => {
    return (
        <AlertDialog open={open} onOpenChange={onOpenChange}>
            <AlertDialogContent className="font-Inter text-xl font-medium  text-black dark:text-white">
                <AlertDialogHeader>
                    <AlertDialogTitle>Terms and Conditions</AlertDialogTitle>
                    <AlertDialogDescription>
                        <p className="mb-4">
                            Please read the Terms and Conditions carefully before using our service.
                        </p>
                        <p className="mb-4">
                            The Jamme Ads platform is managed by Voiaxis Limited and provides a variety of audio, video, and advertising services. By accessing the platform, you agree to comply with these Terms, which include user eligibility, account responsibilities, and content usage rights.
                        </p>
                        <p className="mb-4">
                            If you have any questions or need assistance, please reach out to our support team.
                        </p>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel
                        className="border-2 border-Primary p-3 px-6 rounded-full text-black dark:text-white font-Inter font-semibold 
                        bg-Primary dark:bg-black hover:scale-105 transition-transform transform ease-in-out duration-300"
                        onClick={() => onOpenChange(false)}
                    >
                        Close
                    </AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default TermsAndConditionsDialog;
