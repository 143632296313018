import { useState } from "react";
import {
    AlertDialog,
    AlertDialogTrigger,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogCancel,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";

interface ManageCookiesAlertProps {
    onSaveChanges: (performanceCookies: boolean, advertisingCookies: boolean) => void;
}

const ManageCookiesAlert: React.FC<ManageCookiesAlertProps> = ({ onSaveChanges }) => {
    const [performanceCookies, setPerformanceCookies] = useState(true);
    const [advertisingCookies, setAdvertisingCookies] = useState(true);
    const [isDialogOpen, setIsDialogOpen] = useState(false); // Track whether the dialog is open or closed

    const handleSave = () => {
        onSaveChanges(performanceCookies, advertisingCookies);
        setIsDialogOpen(false); // Close the dialog after saving
    };

    return (
        <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <AlertDialogTrigger asChild>
                <a href="#" className="text-xs underline" onClick={() => setIsDialogOpen(true)}>Manage cookies</a>
            </AlertDialogTrigger>
            <AlertDialogContent className="font-Inter">
                <AlertDialogHeader>
                    <AlertDialogTitle>Manage Cookies</AlertDialogTitle>
                    <AlertDialogDescription>
                        <div className="flex justify-between items-center">
                            <strong>Necessary</strong>
                            <Switch checked={true} disabled />
                        </div>
                        <p className="text-sm">
                            Required to enable core site functionality and to remember user preferences and choices, such as language preferences or customized settings. <em>Always on</em>
                        </p>
                        <br />
                        <div className="flex justify-between items-center">
                            <strong>Performance and Analytics</strong>
                            <Switch checked={performanceCookies} onCheckedChange={setPerformanceCookies} />
                        </div>
                        <p className="text-sm">
                            These cookies provide quantitative measures of website visitors. With the usage of these cookies, we are able to count visits and traffic sources to improve the performance of our site.
                        </p>
                        <br />
                        <div className="flex justify-between items-center">
                            <strong>Advertising</strong>
                            <Switch checked={advertisingCookies} onCheckedChange={setAdvertisingCookies} />
                        </div>
                        <p className="text-sm">
                            These cookies are used by advertising companies to serve ads that are relevant to your interests.
                        </p>
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <Button variant="secondary" className="bg-Primary rounded-full border  border-gray-300" onClick={handleSave}>
                        Save Changes
                    </Button>
                    <AlertDialogCancel asChild>
                        <Button variant="ghost" className="rounded-full hover:bg-black hover:text-white" onClick={() => setIsDialogOpen(false)}>
                            Cancel
                        </Button>
                    </AlertDialogCancel>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default ManageCookiesAlert;
