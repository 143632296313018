import React from "react";
import { Header } from "../internal/header";

export default function DashboardLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="flex flex-col min-h-screen">
      <Header isHomeScreen={false} />
      <main className="flex-grow pt-20">{children}</main>
    </div>
  );
}
