import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { AiOutlineClose } from "react-icons/ai";
import { useThemeStore } from "@/store";
import JammeLogo from "@/assets/jammeLogo.svg";
import JammeDarklogo from "@/assets/jammeDarkLogo.svg";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

// Define the Sidebar component
const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose, user }) => {
  const { theme } = useThemeStore();
  const logoSrc = theme === "dark" ? JammeDarklogo : JammeLogo;
  return (
    <aside
      className={`fixed top-0 right-0 w-72 h-[500px] rounded-l-2xl mt-1 bg-[#FFF7EF] dark:bg-background z-50 transition-transform transform ${isOpen ? "translate-x-0" : "translate-x-full"
        } lg:hidden`}
    >
      <div className="flex flex-col p-4">
        {/* Button to close the sidebar */}

        <button
          aria-label="Close menu"
          className="self-start p-2 rounded-md text-black"
          onClick={onClose}
        >
          <AiOutlineClose className="w-4 h-4 text-Primary dark:text-white" />
        </button>
        <div className="ml-12 mb-12 mt-4">
          <Link to="/" className="flex items-center gap-4">
            <div className="h-12 w-12  md:hidden items-center justify-center rounded-full">
              <img
                src={logoSrc}
                alt="Jamme Logo"
                loading="lazy"
                className="h-11 w-11"
              />
            </div>
            <h3 className="lg:text-2xl text-foreground dark:text-foreground font-Inter font-semibold cursor-pointer">
              Jamme Ads
            </h3>
          </Link>
        </div>
        {/* Conditional sidebar content */}
        {user ? (
          <>
            <Link to="/dashboard" className="mb-4" onClick={onClose}>
              <Button
                variant="default"
                className="bg-[#FFEFE0] p-4 rounded-3xl text-black font-Inter font-medium hover:bg-Primary w-full"
              >
                Analytics
              </Button>
            </Link>
            <Link to="/ads" className="mb-4" onClick={onClose}>
              <Button className="font-Inter rounded-full bg-Primary hover:bg-Primary text-black w-full">
                Create Ad
              </Button>
            </Link>
          </>
        ) : (
          <div className="flex flex-col gap-2 pt-8">
            <Link to="/login">
              <Button
                variant="default"
                className="bg-Primary p-4 rounded-3xl text-black font-Inter font-medium hover:bg-Primary w-full"
              >
                Sign in
              </Button>
            </Link>
            <Link to="/register" className="mb-4" onClick={onClose}>
              <Button
                variant="default"
                className="bg-Primary p-4 rounded-3xl text-black font-Inter font-medium hover:bg-Primary w-full"
              >
                Launch an Ad
              </Button>
            </Link>
          </div>
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
