import React, { useEffect, useState, useMemo, useRef } from "react";
import { UseFormRegister, UseFormSetValue, FieldError } from "react-hook-form";
import { Label } from "@/components/ui/label";
import FormError from "../FormErrors/FormError";
import axios from "axios";
import { FaChevronDown } from "react-icons/fa"; // Icon for dropdown arrow
import { Input } from "@/components/ui/input";

interface Country {
  name: string;
  alpha2Code: string;
  flag: string;
}

interface CountrySelectProps {
  id: string;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  error?: FieldError;
}

const CountrySelect: React.FC<CountrySelectProps> = ({ id, register, setValue, error }) => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error state
  const [searchQuery, setSearchQuery] = useState<string>(""); // Search input state
  const [isOpen, setIsOpen] = useState(false); // State for opening/closing dropdown
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  const dropdownRef = useRef<HTMLDivElement>(null); // To handle outside clicks

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countryData: Country[] = response.data.map((country: any) => ({
          name: country.name.common,
          alpha2Code: country.cca2,
          flag: country.flags.svg,
        }));
        setCountries(countryData);
      } catch (error) {
        console.error("Error fetching countries:", error);
        setErrorMessage("Failed to load countries. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsOpen((prev) => !prev);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleCountrySelect = (country: Country) => {
    setSelectedCountry(country);
    setValue(id, country.alpha2Code); // Update the form value manually
    setIsOpen(false);
  };

  // Memoizing filtered countries based on search query
  const filteredCountries = useMemo(() => {
    if (!searchQuery) return countries;
    return countries.filter((country) =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [searchQuery, countries]);

  return (
    <div className="mb-4 flex flex-col relative">
      <Label htmlFor={id} className="block mb-1">
        Country <span className="text-red-500 mt-2">*</span>
      </Label>

      {/* Custom select input */}
      <div
        className={`border border-gray-300 p-2 rounded-lg flex justify-between items-center cursor-pointer ${isOpen ? "ring-2 ring-blue-500" : ""}`}
        onClick={toggleDropdown}
        aria-haspopup="listbox"
      >
        <span>
          {selectedCountry ? (
            <div className="flex items-center">
              <img
                src={selectedCountry.flag}
                alt={selectedCountry.name}
                className="w-6 h-4 mr-2"
              />
              {selectedCountry.name}
            </div>
          ) : (
            "Select your country"
          )}
        </span>
        <FaChevronDown className="ml-2 text-sm " />
      </div>

      {/* Dropdown */}
      {isOpen && (
        <div ref={dropdownRef} className="absolute top-full left-0 w-full border border-gray-300 mt-2 bg-white rounded-lg shadow-lg z-10 ">
          {/* Search input inside dropdown */}
          <Input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search country..."
            className="border-b border-gray-300 w-full p-2 dark:bg-background"
            aria-label="Search country"
          />
          <ul role="listbox" className="max-h-60 overflow-y-auto dark:bg-background">
            {loading ? (
              <li className="p-2 text-center text-gray-500">Loading...</li>
            ) : errorMessage ? (
              <li className="p-2 text-center text-red-500">{errorMessage}</li>
            ) : (
              filteredCountries.map((country) => (
                <li
                  key={country.alpha2Code}
                  onClick={() => handleCountrySelect(country)}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-500 cursor-pointer flex items-center"
                  role="option"
                >
                  <img
                    src={country.flag}
                    alt={country.name}
                    className="w-6 h-4 mr-2"
                  />
                  {country.name}
                </li>
              ))
            )}
          </ul>
        </div>
      )}

      {/* Hidden input for react-hook-form */}
      <input
        type="hidden"
        {...register(id, { required: "Country is required" })}
        value={selectedCountry ? selectedCountry.alpha2Code : ""}
      />

      {error && <FormError message={error.message} />}
    </div>
  );
};

export default CountrySelect;
