// src/utils/api.ts
import axios, { AxiosError, AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { getAuthCookies } from "./cookies";

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the bearer token to headers
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const user = getAuthCookies();
    const token = user?.token;

    if (token) {
      (config.headers as AxiosRequestHeaders)["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Function to post FormData using the configured axios instance
export const postFormData = async (url: string, data: FormData) => {
  try {
    const response = await api.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Failed to submit form:", error);
    throw error;
  }
};

export const deleteAd = async (adId: string) => {
  try {
    const response = await api.delete(`/ads/deleteAd/${adId}`);
    return response.data;
  } catch (error) {
    console.error("Failed to delete ad:", error);
    throw error;
  }
};

export default api;
