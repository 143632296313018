import React, { useEffect } from 'react';

const ChatWidget = () => {
  useEffect(() => {
    const tawkScript = document.createElement("script");
    tawkScript.type = "text/javascript";
    tawkScript.async = true;
    tawkScript.src = 'https://embed.tawk.to/671224282480f5b4f58f724d/1iafdqfpg'; // Your updated Tawk.to script
    tawkScript.setAttribute('crossorigin', '*');

    const s0 = document.getElementsByTagName("script")[0];

    // Check if s0 and its parentNode exist
    if (s0 && s0.parentNode) {
      s0.parentNode.insertBefore(tawkScript, s0);
    }

    // Optional: Cleanup function to remove the script when the component unmounts
    return () => {
      if (s0 && s0.parentNode) {
        s0.parentNode.removeChild(tawkScript);
      }
    };
  }, []);

  return null; // This component does not render any UI
};

export default ChatWidget;
