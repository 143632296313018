import { useQuery } from "@tanstack/react-query";
import api from "@/utils/api"; // Assuming you have an API utility set up
import useStore from "@/zustand/store"; // Import your Zustand store

// Custom hook for fetching the budget
export const useFetchBudget = () => {
  const { role } = useStore((state) => ({ role: state.role })); // Get user role from Zustand store

  // Check if the user's role is 'VIEWER' or 'EDITOR'
  const isViewer = role === 'VIEWER';
  const isEditor = role === 'EDITOR';

  return useQuery({
    queryKey: ["totalBudget"], // Cache key
    queryFn: async () => {
      if (isViewer || isEditor) {
        // Throw the same error for both roles
        throw new Error("You do not have permission to view the budget.");
      }

      const response = await api.get("/wallet/getBudget");
      return Number(response.data?.totalBudget) || 0; // Return total budget for allowed roles
    },
    staleTime: 5 * 60 * 1000, // 5 minutes cache time
    retry: 2, // Retry failed requests up to 2 times
    refetchOnWindowFocus: false, // Disable refetch on window focus
  });
};
