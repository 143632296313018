import * as React from "react"
import { cn } from "@/lib/utils"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import LanguageSelector from "@/components/selector";
import JammeLogo from "@/assets/jammeLogo.svg";
import { Link } from "react-router-dom";

export default function NavigationMenuDemo() {
  return (
    <NavigationMenu className="font-Inter">
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger>Resources</NavigationMenuTrigger>
          <NavigationMenuContent>
            <ul className="grid gap-3 p-6 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
              <li className="row-span-3">
                <NavigationMenuLink asChild>
                  <a
                    className="flex h-full w-full select-none flex-col items-center justify-center rounded-md bg-orange-100 p-6 no-underline outline-none focus:shadow-md"
                    href="/"
                  >
                    <img src={JammeLogo} className="h-20 w-20" alt="" />
                    <div className="mb-2 mt-4 text-lg font-medium dark:text-black">
                      Jamme Ads
                    </div>
                  </a>
                </NavigationMenuLink>
              </li>
              <ListItem href="/docs/overview" title="Jamme ads overview">
                A comprehensive guide to understanding Jamme's advertising platform and its key features.
              </ListItem>
              <ListItem href="/docs/suspend" title="Account Suspended">
                Account suspended. Please follow the instructions to resolve the issue.
              </ListItem>
              <ListItem href="/docs/guidelines" title="Jamme User Guidelines">
                Familiarize yourself with the Jamme User Guidelines to ensure a positive experience while using our services. Adherence to these guidelines helps maintain a safe and enjoyable environment for all users.
              </ListItem>
              <ListItem href="/docs/policies" title="Jamme ads policies">
                Essential rules and guidelines for running compliant ads on the Jamme platform.
              </ListItem>
              <ListItem href="/docs/adsformat" title="Jamme ads format">
                Learn how Jamme ensures a safe environment for advertisers by protecting brand reputation.
              </ListItem>
              <ListItem href="/docs/targeting" title="Targeting">
                Discover tools and metrics to measure and improve the effectiveness of your Jamme ads.
              </ListItem>
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
        <LanguageSelector />
        <NavigationMenuItem>
          <Link to="/docs">
            <NavigationMenuLink className={navigationMenuTriggerStyle()}>
              {""}
            </NavigationMenuLink>
          </Link>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}
const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"
