// store.ts
import { StateCreator } from "zustand";
import api from "../utils/api"; // Adjust import based on your project structure
import { handleApiError } from "../utils/errorHandler";
import { VatData } from "@/components/types/internal/types/registerTypes";

// Define the shape of the VAT collection state
export interface VatCollectionState {
  vatData: VatData | null;
  collectUserVat: (vatData: VatData) => Promise<{ message: string } | false>;
  error: string | null;
  loading: boolean;
}

// Create the VAT collection slice
export const createVatCollectionSlice: StateCreator<VatCollectionState> = (
  set
) => ({
  vatData: null,
  error: null,
  loading: false,

  // Collect User VAT method
  collectUserVat: async (vatData: VatData) => {
    set({ loading: true, error: null });
    try {
      const response = await api.post("/payment/collectUserVat", vatData);
      return response.data; // Return the response object
    } catch (error: any) {
      set({ error: handleApiError(error) });
      return false;
    } finally {
      set({ loading: false });
    }
  },
});
