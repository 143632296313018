import React from 'react';
import Img1 from '@/assets/Frame 185317.svg';
import Img2 from "@/assets/stepIndicator.png";

interface StepIndicatorProps {
    currentStep: number; // Current step the user is on
}

const stepNames = [
    "Create your account", // Step 1
    "Register your business" // Step 2
];

const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep }) => {
    return (
        <div className="flex justify-center gap-4 font-Inter">
            {Array.from({ length: 2 }, (_, index) => {
                const stepNumber = index + 1;
                const isCompleted = stepNumber < currentStep; // Check if step is completed
                const isCurrent = stepNumber === currentStep; // Check if step is current

                return (
                    <div key={index} className="flex flex-col items-center">
                        <img
                            src={isCompleted ? Img2 : Img1} // Choose image based on step status
                            alt={`Step ${stepNumber}`}
                            className=" w-[130px] md:w-[206px] lg:w-[206px] lg:h-[33px]" // Use Tailwind CSS classes for width and height
                        />
                        <p className={`text-xs md:text-base lg:text-base mt-2  font-medium ${isCurrent ? 'text-primary' : 'text-gray-500'}`}>
                            {stepNames[index]} {/* Display step name */}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default StepIndicator;
