const ImageLoader = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-50 dark:bg-gray-900">
      <img
        src="https://jamme.app/assets/Logo.png"
        alt="Loading"
        className="w-16 h-16 animate-spin-slow" // Adjust the size and animation as needed
      />
    </div>
  );
};

export default ImageLoader;
