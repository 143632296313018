import React, { useCallback, useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Button } from "@/components/ui/button";
import useStore from "@/zustand/store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useToast } from "@/components/ui/use-toast";
import { handleApiError } from "@/utils/errorHandler";
import ReCAPTCHA from "react-google-recaptcha";
import { Mixpanel } from "@/utils/Mixpanel"; // Import Mixpanel

// Define validation schema using Zod
const schema = z.object({
  phoneNumber: z
    .string()
    .min(10, "Phone number must be at least 10 digits")
    .max(15, "Phone number must be at most 15 digits"),
  countryCode: z.string(),
});

// Infer form data type from schema
type FormData = z.infer<typeof schema>;

interface RegisterFormProps {
  onSuccess: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onSuccess }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const {
    sendOtp,
    error: registerError,
    loading,
    setPhoneNumber,
    setCountryCode,
  } = useStore((state) => ({
    sendOtp: state.sendOtp,
    error: state.error,
    loading: state.loading,
    setPhoneNumber: state.setPhoneNumber,
    setCountryCode: state.setCountryCode,
  }));

  const { toast } = useToast();

  const [dropdownWidth, setDropdownWidth] = useState("320px");
  const [dropdownFontSize, setDropdownFontSize] = useState("14px");

  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setDropdownWidth("224px");
      setDropdownFontSize("12px");
    } else {
      setDropdownWidth("320px");
      setDropdownFontSize("14px");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    if (!captchaToken) {
      toast({
        title: "Error",
        description: "Please complete the CAPTCHA.",
        variant: "destructive",
      });
      return;
    }

    // Track registration attempt
    Mixpanel.track("Registration Attempt", {
      phoneNumber: data.phoneNumber,
      countryCode: data.countryCode,
      timestamp: new Date(),
    });

    setPhoneNumber(data.phoneNumber);
    setCountryCode(data.countryCode);
    try {
      await sendOtp(data.countryCode);
      toast({
        title: "Success",
        description: "OTP sent successfully",
        variant: "default",
        className: "bg-green-500 text-white",
      });

      // Track successful registration
      Mixpanel.track("Registration Success", {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
        timestamp: new Date(),
      });

      // Identify the user
      Mixpanel.identify(data.phoneNumber);

      onSuccess();
    } catch (error) {
      const errorMessage = handleApiError(error);
      toast({
        title: "Error",
        description: errorMessage || "Failed to send OTP. Please try again.",
        variant: "destructive",
      });

      // Track failed registration
      Mixpanel.track("Registration Failure", {
        phoneNumber: data.phoneNumber,
        countryCode: data.countryCode,
        timestamp: new Date(),
        error: errorMessage,
      });
    }
  };

  const handlePhoneChange = useCallback(
    (value: string, country: { dialCode?: string }) => {
      if (!country || !country.dialCode) {
        toast({
          title: "Error",
          description: "Invalid country code.",
          variant: "destructive",
        });
        return;
      }

      const countryCodeWithPlus = `+${country.dialCode}`;
      const phoneNumberWithoutCountryCode = value.startsWith(country.dialCode)
        ? value.slice(country.dialCode.length)
        : value;

      setValue("phoneNumber", phoneNumberWithoutCountryCode);
      setValue("countryCode", countryCodeWithPlus);
      setCountryCode(countryCodeWithPlus);
    },
    [setValue, setCountryCode, toast]
  );

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-3">
      <label
        htmlFor="phone"
        className="block font-Inter text-sm font-medium text-gray-700 dark:text-white"
      >
        Enter phone number
      </label>
      <div className="flex items-center space-x-2 w-full pb-2">
        <PhoneInput
          country={"in"}
          onChange={handlePhoneChange}
          inputStyle={{ width: "100%", color: "#000000" }}
          dropdownStyle={{ width: dropdownWidth, fontSize: dropdownFontSize }}
        />
      </div>
      {errors.phoneNumber && (
        <span className="text-red-600 text-xs flex items-end justify-end">
          {errors.phoneNumber.message}
        </span>
      )}
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        onChange={handleCaptchaChange}
        className=" md:ml-10 lg:ml-10"
      />
      <Button
        type="submit"
        disabled={loading || !captchaToken}
        className={`w-72 md:w-96 rounded-full flex justify-center items-center font-Inter font-semibold shadow-md transition-transform transform ease-in-out duration-300 ${loading
          ? "bg-[#FFE7D0] text-black cursor-not-allowed"
          : "bg-Primary text-black hover:bg-Primary hover:bg-opacity-90 hover:scale-105"
          }`}
      >
        {loading ? (
          <>
            <svg
              className="animate-spin h-5 w-5 text-black mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.962 7.962 0 014 12H2c0 2.042.784 3.898 2.083 5.291l1.417-1.417z"
              ></path>
            </svg>
            Loading...
          </>
        ) : (
          "Next"
        )}
      </Button>
    </form>
  );
};

export default RegisterForm;
