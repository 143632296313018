import { Upload, Eye, Trash } from "lucide-react";
import React, { useState } from "react";
import { useToast } from "@/components/ui/use-toast"; // Import the toast hook

interface GSTDocumentInputProps {
  id: string;
  register: any; // Replace with the appropriate type for register
  error?: { message?: string }; // Replace with the appropriate error type
}

const GSTDocumentInput: React.FC<GSTDocumentInputProps> = ({ id, register, error }) => {
  const [fileInfo, setFileInfo] = useState<{ type: string; name: string; size: string; url: string } | null>(null);
  const { toast } = useToast(); // Initialize the toast

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      // Check file size
      if (file.size > 2 * 1024 * 1024) { // Check if file is larger than 2MB
        toast({ // Display toast notification
          title: "Error",
          description: "File size exceeds 2MB.",
          variant: "destructive",
        });
        setFileInfo(null); // Clear file info
        return; // Exit the function
      }

      const fileType = file.type.split("/")[1]; // Get the file type (e.g., 'jpeg', 'pdf')
      const fileName = file.name; // Get the file name
      const fileSize = `${(file.size / (1024 * 1024)).toFixed(2)} MB`; // Convert size to MB
      const fileUrl = URL.createObjectURL(file); // Create a URL for the uploaded file

      setFileInfo({ type: fileType, name: fileName, size: fileSize, url: fileUrl });

      // Trigger registration update
      register.onChange(event);
    } else {
      setFileInfo(null);
    }
  };

  const handleDelete = () => {
    setFileInfo(null);
    (document.getElementById(id) as HTMLInputElement).value = ""; // Clear the input
  };

  const handleView = () => {
    if (fileInfo) {
      window.open(fileInfo.url);
    }
  };

  return (
    <div className="flex flex-col space-y-2 mb-4">
      <label className="font-Inter font-medium text-sm">
        Upload Tax Document <span className="text-red-500">*</span>
      </label>
      <div
        className={`relative cursor-pointer h-32 p-4 bg-gray-100  dark:bg-background
          ${fileInfo ? "" : `border-2 border-dotted ${error ? "border-red-500" : "border-gray-300"}`} 
          rounded-[32px] flex flex-col items-center justify-center transition duration-300`}
        onClick={() => (document.getElementById(id) as HTMLInputElement)?.click()}
      >
        {fileInfo ? (
          <div className="flex items-center p-2 rounded-md w-full h-auto space-x-4">
            <div className="flex items-center">
              <div className="bg-red-500 text-white font-bold p-1 rounded text-xs">
                {fileInfo.type}
              </div>
            </div>
            <div className="flex flex-col flex-grow truncate">
              <span className="font-semibold truncate w-full sm:w-40">{fileInfo.name}</span>
              <span className="text-sm text-gray-500">{fileInfo.size}</span>
            </div>
            <div className="flex space-x-2">
              <button
                className="focus:outline-none hover:scale-110 transition-transform"
                onClick={handleView}
                title="View Document"
              >
                <Eye size={24} className="text-black dark:text-white" />
              </button>
              <button
                className="focus:outline-none hover:scale-110 transition-transform"
                onClick={handleDelete}
                title="Delete Document"
              >
                <Trash size={24} className="text-red-500" />
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center space-x-2">
              <Upload size={24} className="text-gray-600 " />
              <p className="text-sm text-gray-600">Upload Document</p>
            </div>
            <div className="text-sm text-gray-500 mt-2 text-center">
              <p>Document type: .jpg, .pdf, .png, .jpeg</p>
              <p>Document size: Maximum 2MB</p>
            </div>
          </>
        )}
      </div>

      <input
        id={id}
        type="file"
        {...register}
        onChange={handleChange}
        accept=".jpg, .jpeg, .png, .pdf"
        className="hidden"
        aria-label="Upload tax document"
      />

      {error && <p className="text-red-500 text-sm">{error.message}</p>}
    </div>
  );
};

export default GSTDocumentInput;
