// SuspenseWrapper.tsx
import { ReactNode } from "react";
import { Suspense } from "react";
import PageTransition from "./PageTransition";
import Loading from "@/pages/common/Loading";
import { useLocation } from "react-router-dom";

interface SuspenseWrapperProps {
  children: ReactNode;
}

export default function SuspenseWrapper({ children }: SuspenseWrapperProps) {
  const location = useLocation();
  return (
    <Suspense fallback={<Loading />}>
      <PageTransition key={location.pathname}>{children}</PageTransition>
    </Suspense>
  );
}
