import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { Theme, ThemeStore } from "@/store/theme/types";

const useThemeStore = create<ThemeStore>()(
  devtools(
    persist(
      (set) => ({
        theme: "system",
        setTheme: (theme: Theme) => set({ theme }),
      }),
      { name: "theme-storage" }
    )
  )
);

export default useThemeStore;
