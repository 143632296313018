import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useLogout } from "@/hooks/useLogout";
import { LogoutConfirmationDialog } from "../logoutDialog/LogoutConfirmationDialog";
import { DollarSign, UserPlus, LogOut } from "lucide-react";
import { Link } from "react-router-dom";
import { useFetchBudget } from "@/hooks/useFetchBudget"; // Import the custom hook
import { getUserInfoFromLocalStorage } from "@/utils/localStorage";
// Import your Zustand hook to access authentication state

export default function UserNav() {
  const { open, setOpen, handleLogout, confirmLogout } = useLogout();

  // Fetch user authentication details from the Zustand store
  const { companyName, email, logo } = getUserInfoFromLocalStorage();  // Access the auth details

  // Use the custom hook to fetch the budget
  const { data: totalBudget, isLoading, error } = useFetchBudget();

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Avatar className="h-10 w-10 border cursor-pointer">
            {/* Use the logo from Zustand store or fallback to a default avatar */}
            <AvatarImage
              src={logo || "https://png.pngtree.com/png-clipart/20230927/original/pngtree-man-avatar-image-for-profile-png-image_13001882.png"}
              alt="User Logo"
              className="object-cover rounded-full w-10 h-10"  // Ensures the logo looks clean
            />
            <AvatarFallback>J</AvatarFallback>
          </Avatar>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              {/* Use companyName and email from Zustand store */}
              <p className="text-sm font-medium leading-none">
                {companyName || "Company Name"} {/* Fallback in case it's null */}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {email || "user@example.com"} {/* Fallback in case it's null */}
              </p>
              {/* {isLoading ? (
                <p className="text-xs leading-none text-muted-foreground">
                  Loading balance...
                </p>
              ) : error ? (
                <p className="text-xs leading-none text-red-500">
                  Unable to load balance.
                </p>
              ) : (
                <p className="text-xs leading-none text-muted-foreground">
                  Total Balance: ${totalBudget} USD
                </p>
              )} */}
            </div>
          </DropdownMenuLabel>

          <DropdownMenuGroup>
            <Link to="/wallet">
              <DropdownMenuItem>
                <DollarSign className="mr-2 h-4 w-4" />
                <span>Add Balance to Wallet</span>
              </DropdownMenuItem>
            </Link>
            <Link to="/accountaccess">
              <DropdownMenuItem>
                <UserPlus className="mr-2 h-4 w-4" />
                <span>Invite users for Collaboration</span>
              </DropdownMenuItem>
            </Link>
          </DropdownMenuGroup>

          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleLogout}>
            <LogOut className="mr-2 h-4 w-4" />
            <span>Log out</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <LogoutConfirmationDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={confirmLogout}
      />
    </>
  );
}
