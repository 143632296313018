import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ChevronDown } from 'lucide-react';
// Define languages with codes and full names
const languages = [
  { code: "en", lang: "English", flag: "🇬🇧" },
  { code: "fr", lang: "French", flag: "🇫🇷" },
  { code: "hi", lang: "Hindi", flag: "🇮🇳" },
  { code: "ar", lang: "Arabic", flag: "🇸🇦" },
  { code: "es", lang: "Spanish", flag: "🇪🇸" },
];

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };
  const handleDropdownOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  // Get the code for display in the button
  const currentLanguageCode = languages.find((lng) => lng.code === currentLanguage)?.code || "Select Language";

  return (
    <DropdownMenu modal={false} onOpenChange={handleDropdownOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button
          className="bg-orange-150 hover:bg-orange-150 text-black dark:text-white "
          aria-label="Select language"
        >
          {currentLanguageCode}
          <ChevronDown
            className={`relative top-[1px] ml-1 h-3 w-3 transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
          />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Select Language</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={currentLanguage}
          onValueChange={handleLanguageChange}
        >
          {languages.map(({ code, lang, flag }) => (
            <DropdownMenuRadioItem key={code} value={code}>
              {flag} {lang}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default LanguageSelector;
