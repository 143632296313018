import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Initialize i18n
i18n
  .use(LanguageDetector)          // Detect user language
  .use(initReactI18next)          // Integrate with React
  .use(Backend)                   // Load translations from backend
  .init({
    debug: true,                  // Enable debugging
    fallbackLng: 'en',            // Fallback language
    returnObjects: true,          // Return objects for translation
    interpolation: {
      escapeValue: false,         // React already handles escaping
    },
    // Optionally add additional configurations here
  });

export default i18n;
