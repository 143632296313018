import { StateCreator } from "zustand";
import api from "../utils/api";
import { handleApiError } from "../utils/errorHandler";
import {
  setAuthCookies,
  clearAuthCookies,
  getAuthCookies,
} from "../utils/cookies";
import { setUserInfoInLocalStorage, clearUserInfoFromLocalStorage } from "../utils/localStorage";

// Define the shape of the authentication state
export interface LoginAuthState {
  phoneNumber: string;
  countryCode: string;
  user: { token: string } | null;
  companyName: string | null;  // Add companyName to the state
  email: string | null;        // Add email to the state
  logo: string | null;         // Add logo to the state
  orderId: string | null;
  error: string | null;
  loading: boolean;
  updatePhoneNumber: (phoneNumber: string) => void;
  updateCountryCode: (countryCode: string) => void;
  requestOtp: () => Promise<void>;
  resendOtpLogin: () => Promise<void>;
  validateOtp: (otp: string) => Promise<boolean>;
  logout: () => void;
}

// Create the authentication slice
export const createLoginAuthSlice: StateCreator<LoginAuthState> = (
  set,
  get
) => ({
  phoneNumber: "",
  countryCode: "",
  companyName: null,  // Initialize companyName as null
  email: null,        // Initialize email as null
  logo: null,         // Initialize logo as null
  user: getAuthCookies() || null,
  orderId: null, // Initialize orderId as null
  error: null,
  loading: false,

  // Update phone number
  updatePhoneNumber: (phoneNumber: string) => set({ phoneNumber }),

  // Update country code
  updateCountryCode: (countryCode: string) => set({ countryCode }),

  // Request OTP and store orderId from the response
  requestOtp: async () => {
    set({ loading: true, error: null });
    try {
      const { phoneNumber, countryCode } = get();
      const response = await api.post<{ message: string; data: { orderId: string } }>("/auth/sendOTPtoLogin", {
        phoneNumber,
        countryCode,
      });
      const { orderId } = response.data.data; // Extract orderId from response
      set({ orderId }); // Save orderId in the state
      console.log(response.data.message); // Optionally log the success message
    } catch (error: any) {
      set({ error: handleApiError(error) });
      throw error;
    } finally {
      set({ loading: false });
    }
  },

  // Resend OTP request
  resendOtpLogin: async () => {
    set({ loading: true, error: null });
    try {
      const { phoneNumber, countryCode } = get();
      const params = { countryCode, phoneNumber };
      await api.post("/auth/resendOTP", params);
    } catch (error: any) {
      set({ error: handleApiError(error) });
    } finally {
      set({ loading: false });
    }
  },

  // Validate OTP with the orderId
  validateOtp: async (otp: string) => {
    set({ loading: true, error: null });
    try {
      const { phoneNumber, countryCode, orderId } = get();

      if (!orderId) {
        throw new Error("Order ID is missing. Please request OTP again.");
      }

      const response = await api.post("/auth/verifyOTPForLogin", {
        otp,
        phoneNumber,
        countryCode,
        orderId, // Include orderId in the request
      });

      const { token, companyName, email, logo } = response.data;
      setAuthCookies({
        token,
        role: null,
      });
      setUserInfoInLocalStorage(companyName, email, logo);

      set({ user: { token }, companyName, email, logo, orderId: null }); // Clear orderId after validation

      return true;
    } catch (error: any) {
      set({ error: handleApiError(error) });
      clearAuthCookies();
      set({ user: null, orderId: null }); // Clear orderId on failure
      return false;
    } finally {
      set({ loading: false });
    }
  },

  // Sign out
  logout: () => {
    clearAuthCookies();
    clearUserInfoFromLocalStorage();
    set({ user: null, orderId: null }); // Clear orderId on sign out
  },
});
